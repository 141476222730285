<template>
  <div class="input-group d-flex align-items-center">
    <span class="search-icon d-flex justify-content-center">
      <div
        v-if="loading"
        class="spinner-border spinner-border-sm"
        style="width: 1.05rem; height: 1.05rem; color: rgba(0, 0, 0, 0.2)"
      ></div>

      <mdicon v-else name="magnify" size="20" />
    </span>
    <input
      type="text"
      class="form-control form-control-sm"
      :placeholder="placeholder ?? 'Search'"
      :value="modelValue"
      :disabled="disabled"
      @input="handleInput"
    />
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  loading?: boolean;
  modelValue?: string;
  placeholder?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", searchStr: string): void;
  (e: "search"): void;
}>();

const handleInput = (e) => {
  emit("update:modelValue", e.target.value);
  emit("search");
};
</script>

<style scoped>
.search-icon {
  width: 40px;
  height: 35.51px;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary);
  color: #fff;
}

.search-icon :deep(svg) {
  width: inherit;
  height: inherit;
}

.form-control:focus {
  box-shadow: none;
}
</style>
