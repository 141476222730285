import $api from "@/api";
import $apiv3 from "@/apiv3";
import { defineStore, storeToRefs } from "pinia";
import type { MessageProvider } from "./types/inbox";
import type { PostDraft, State } from "./types/msg";
import { customAlphabet } from "nanoid";
import { useThreadStore } from "./thread";
import type { AxiosResponse } from "axios";

export const useMsgStore = defineStore({
  id: "msg",
  state: (): State => ({
    msgComposers: [],
    postDraftsMap: {},
  }),
  actions: {
    __getProcessedChatHtml(html: string): string {
      const $temp = $(`<div>${html}</div>`);
      // eslint-disable-next-line
      $temp.find("p:empty").html("<br/>");
      // eslint-disable-next-line
      return $temp.html();
    },

    /**
     * Live chat msg sender.
     */
    async sendChatMsg(
      inboxId: string,
      threadId: string,
      msgHtml: string,
      attachmentIds?: number[],
      msgText?: string
    ) {
      const threadStore = useThreadStore();

      const htmlMsg = this.__getProcessedChatHtml(msgHtml);
      // Push the msg in the threadItems
      const { tempId } = threadStore.asyncInsertMsgThreadItem(
        threadId,
        "chat",
        htmlMsg,
        msgText ?? htmlMsg
      );

      const res = await $api.post("/unifiedv2/sendChatInboxMessage.php", {
        mailboxID: inboxId,
        to: threadId,
        message: htmlMsg,
        attachmentId: attachmentIds,
      });

      const { status, message } = res.data;
      const msgData: {
        client_number: number;
        old_thread_id: number;
        contact_id: number;
        message_time: string;
        body: string;
        type: 0 | 1;
        delivery_status: "Sent";
        sent_by: {
          id: number;
          firstname: string;
          lastname: string;
          email: string;
        };
        attachment: {
          id: number;
          mime_type: string;
          url: string;
          name: string;
          size: string;
        }[];
        message_id: number;
        firstReply: false;
      } = res.data.data;

      if (status.includes("error")) {
        threadStore.updateAsyncMsgThreadItem("failed", tempId, threadId);
        throw new Error(message);
      }

      threadStore.updateAsyncMsgThreadItem(
        "success",
        tempId,
        threadId,
        msgData.message_id.toString(),
        {
          dataType: "chat",
          ...msgData,
        }
      );
    },
    /**
     * SMS msg sender depending on inboxType and inboxSubType.
     */
    async sendSMSMsg(
      ph: string[],
      inboxId: string,
      messageProvider: MessageProvider,
      msg: string,
      threadId?: string,
      sendAt?: string,
      attachmentIds?: number[],
      msgText?: string
    ): Promise<void> {
      const threadStore = useThreadStore();

      let url = "sendSmsUnifiedLive.php";
      let attachmentIdPropName = "attachmentId";

      if (sendAt) {
        url = "scheduleSmsUnifiedLive.php";
        attachmentIdPropName = "attachmentIDs";
      }

      let tempId = "";

      if (threadId) {
        // Push the msg in the threadItems
        const { tempId: _tempId } = threadStore.asyncInsertMsgThreadItem(
          threadId,
          "sms",
          msg,
          msgText
        );

        tempId = _tempId;
      }

      let res: AxiosResponse<any, any>;
      if (messageProvider === "ringcentral") {
        url = `/ringcentral/${url}`;
        for (const i in ph) {
          const phoneNo = ph[i];
          res = await $api.post(url, {
            [attachmentIdPropName]: attachmentIds ?? [],
            mailboxID: inboxId,
            to: phoneNo,
            message: msg,
            sendAt,
          });

          const { status, message } = res.data;

          if (status.includes("error")) {
            if (threadId) {
              threadStore.updateAsyncMsgThreadItem("failed", tempId, threadId);
            }

            throw new Error(message);
          }
          const msgData: {
            attachments: {
              id: number;
              media_url: string;
              content_type: string;
            }[]; // getting empty from sms
            id: number;
            [key: string]: any;
          } | null = res.data.data;

          if (threadId && msgData) {
            threadStore.updateAsyncMsgThreadItem(
              "success",
              tempId,
              threadId,
              msgData.id.toString(),
              {
                dataType: "sms",
                attachments: msgData.attachments ?? [],
              }
            );
          }
        }
      } else {
        url = `/smsWhatsApp/sendMsg`;
        for (const i in ph) {
          const phoneNo = ph[i];

          if (sendAt) {
            const originalDate = new Date(sendAt);
            const formattedDateString = originalDate.toISOString();

            res = await $apiv3.post(url, {
              inboxId: Number(inboxId),
              clientNumber: phoneNo,
              body: msg,
              attachmentIds,
              sendAt: formattedDateString,
              scheduleMsg: true,
            });
          } else {
            res = await $apiv3.post(url, {
              inboxId: Number(inboxId),
              clientNumber: phoneNo,
              body: msg,
              attachmentIds,
            });
          }

          const { status, message } = res.data;

          if (status.includes("error")) {
            if (threadId) {
              threadStore.updateAsyncMsgThreadItem("failed", tempId, threadId);
            }
            throw new Error(message);
          }

          const msgData: {
            attachments: {
              id: number;
              url: string;
              contentType: string;
            }[]; // getting empty from sms
            id: number;
            [key: string]: any;
          } | null = res.data.data;

          if (threadId && msgData) {
            threadStore.updateAsyncMsgThreadItem(
              "success",
              tempId,
              threadId,
              msgData.id.toString(),
              {
                dataType: "smsNew",
                attachments: msgData.attachments ?? [],
              }
            );
          }
        }
      }
    },
    async sendWhatsappMsg(
      ph: string[],
      inboxId: string,
      messageProvider: MessageProvider,
      msg: string,
      attachmentId?: number,
      threadId?: string,
      sendAt?: string,
      template?: {
        category: string;
        parameters: { type: "text"; text: string }[];
        headerParameters: { type: "text"; text: string }[];
        imageParameter: string;
        locationParameter: { placeholder: string; val: string }[];
        language: string;
        name: string;
        namespace: string;
        id: number;
      }
    ): Promise<void> {
      const threadStore = useThreadStore();

      const url = "smsWhatsApp/sendMsg";

      let tempId = "";

      if (threadId) {
        // Push the msg in the threadItems
        const { tempId: _tempId } = threadStore.asyncInsertMsgThreadItem(
          threadId,
          "whatsapp",
          msg
        );

        tempId = _tempId;
      }

      const parameters: string[] = [];

      template?.parameters.forEach((parameter) => {
        return parameters.push(parameter.text);
      });

      const headerParameters: string[] = [];

      template?.headerParameters.forEach((parameter) => {
        return headerParameters.push(parameter.text);
      });

      if (template?.imageParameter) {
        headerParameters.push(template?.imageParameter);
      }

      const locationParameters: {
        latitude: string;
        longitude: string;
        name: string;
        address: string;
      } = {
        latitude: "",
        longitude: "",
        name: "",
        address: "",
      };

      template?.locationParameter.forEach((parameter) => {
        if (parameter.placeholder === "latitude") {
          locationParameters.latitude = parameter.val;
        } else if (parameter.placeholder === "longitude") {
          locationParameters.longitude = parameter.val;
        } else if (parameter.placeholder === "name") {
          locationParameters.name = parameter.val;
        } else if (parameter.placeholder === "address") {
          locationParameters.address = parameter.val;
        }
      });

      const attachmentIds: number[] = [];
      if (attachmentId) {
        attachmentIds.push(attachmentId);
      }

      let res: AxiosResponse<any, any>;

      for (const i in ph) {
        const phoneNo = ph[i];
        if (sendAt) {
          const originalDate = new Date(sendAt);
          const formattedDateString = originalDate.toISOString();

          res = await $apiv3.post(url, {
            body: msg,
            clientNumber: phoneNo,
            inboxId: Number(inboxId),
            attachmentIds,
            template: {
              templateId: template?.id,
              templateVariables: parameters,
              headerVariables: headerParameters,
              locationVariables: locationParameters,
            },
            sendAt: formattedDateString,
            scheduleMsg: true,
          });
        } else {
          res = await $apiv3.post(url, {
            body: msg,
            clientNumber: phoneNo,
            inboxId: Number(inboxId),
            attachmentIds,
            template: {
              templateId: template?.id,
              templateVariables: parameters,
              headerVariables: headerParameters,
              locationVariables: locationParameters,
            },
          });
        }

        const { status, message } = res.data;

        if (status.includes("error")) {
          if (threadId) {
            threadStore.updateAsyncMsgThreadItem("failed", tempId, threadId);
          }
          throw new Error(message);
        }

        const msgData: {
          attachments: {
            contentType: string;
            url: string;
            extension: string;
          }[]; // getting empty from sms
          id: number;
          [key: string]: any;
        } | null = res.data.data;

        if (threadId && msgData && sendAt) {
          threadStore.updateAsyncMsgThreadItem(
            "success",
            tempId,
            threadId,
            msgData.id.toString(),
            {
              dataType: "whatsappScheduled",
              attachments: msgData.attachments ?? [],
            }
          );
        }
        if (threadId && msgData) {
          threadStore.updateAsyncMsgThreadItem(
            "success",
            tempId,
            threadId,
            msgData.id.toString(),
            {
              dataType: "whatsapp",
              attachments: msgData.attachments ?? [],
            }
          );
        }
      }
    },
    async sendMetaMsg(
      type: "fb" | "insta",
      inboxId: number,
      threadId: number,
      msg: string,
      attachmentId: number
    ): Promise<void> {
      const threadStore = useThreadStore();

      const nanoid = customAlphabet("1234567890", 14);
      let url = "/instagram-dm/sendMessage.php";

      if (type === "fb") {
        url =
          import.meta.env.VITE_BASE_URL +
          "facebook-integration/api/sendMessage.php";
      }

      const data: Record<string, any> = {
        mailboxID: inboxId,
        message: msg,
        random_id: parseInt(nanoid()),
        threadID: threadId,
        attachmentId,
      };

      // Push the msg in the threadItems
      const { tempId } = threadStore.asyncInsertMsgThreadItem(
        threadId.toString(),
        type === "fb" ? "facebook" : "instagram",
        msg
      );

      // if attachmentId and msg both are present, send attachmentId first and then msg
      if (msg && attachmentId) {
        data.message = "";

        const uploadRes = await $api.post(url, data);

        const { status, message } = uploadRes.data;
        const uploadResData: {
          id: number;
          attachment: {
            filename: string;
            attachment_url: string;
            attachment_type: string;
            extension: string;
            filesize: string;
            id: string;
          }[];
        } = uploadRes.data.data;

        if (status.includes("error")) {
          throw new Error(message);
        }

        if (uploadResData) {
          threadStore.updateAsyncMsgThreadItem(
            "success",
            tempId,
            threadId.toString(),
            uploadResData.id.toString(),
            {
              dataType: "meta",
              attachment: uploadResData.attachment,
            }
          );
        }

        data.message = msg;
      }

      delete data.attachmentId;

      // TODO: This api has "api" after the facebook-integration
      // Backend need to fix it.
      const res = await $api.post(url, data);

      const { status, message } = res.data;

      if (status.includes("error")) {
        threadStore.updateAsyncMsgThreadItem(
          "failed",
          tempId,
          threadId.toString()
        );

        throw new Error(message);
      }

      const resData: {
        [key: string]: any;
      } = res.data.data;

      if (resData) {
        threadStore.updateAsyncMsgThreadItem(
          "success",
          tempId,
          threadId.toString(),
          resData.id.toString()
        );
      }
    },
    async sendInstaMsg(
      inboxId: number,
      threadId: number,
      msg: string,
      attachmentId: number
    ): Promise<void> {
      const threadStore = useThreadStore();

      const nanoid = customAlphabet("1234567890", 14);
      const url = `${
        import.meta.env.VITE_BASE_APIV3_URL
      }instagramDm/${threadId}`;

      const data: Record<string, any> = {
        inboxId,
        text: msg,
        randomId: parseInt(nanoid()),
      };
      if (attachmentId) {
        data["attachmentId"] = attachmentId.toString();
      }

      const { tempId } = threadStore.asyncInsertMsgThreadItem(
        threadId.toString(),
        "instagram",
        msg
      );

      const res = await $api.post(url, data);

      const { status, message } = res.data;

      const resData: {
        [key: string]: any;
      } = res.data;

      if (status.includes("error")) {
        threadStore.updateAsyncMsgThreadItem(
          "failed",
          tempId,
          threadId.toString()
        );

        throw new Error(message);
      }

      if (Object.prototype.hasOwnProperty.call(resData, "id")) {
        threadStore.updateAsyncMsgThreadItem(
          "success",
          tempId,
          threadId.toString(),
          resData.id.toString()
        );
      }
      if (Object.prototype.hasOwnProperty.call(resData, "attachment")) {
        const attachmentArr: any[] = [];
        attachmentArr.push({
          filename: resData.attachment.data.filename,
          attachment_url: resData.attachment.data.url,
          attachment_type: resData.attachment.data.contentType,
          extension: resData.attachment.data.extension,
          filesize: resData.attachment.data.filesize,
          id: resData.attachment.data.id,
        });
        threadStore.updateAsyncMsgThreadItem(
          "success",
          tempId,
          threadId.toString(),
          resData.attachment.data.id.toString(),
          {
            dataType: "meta",
            attachment: attachmentArr,
          }
        );
      }
    },
    async sendTwitterDM(
      inboxId: string,
      threadId: number,
      msg: string,
      attachmentId: number,
      handle: string
    ): Promise<void> {
      const threadStore = useThreadStore();

      const url = "/twitter-dm/send-dm.php";

      let tempId = "";

      if (threadId) {
        const { tempId: _tempId } = threadStore.asyncInsertMsgThreadItem(
          threadId.toString(),
          "twitterdm",
          msg
        );
        tempId = _tempId;
      }

      const res = await $api.post(url, {
        mailboxID: inboxId,
        to: threadId,
        message: msg,
        attachmentId,
        handle,
      });

      const { status, message } = res.data;

      if (status.includes("error")) {
        if (threadId) {
          threadStore.updateAsyncMsgThreadItem(
            "failed",
            tempId,
            threadId.toString()
          );
        }
        throw new Error(message);
      }

      const msgData: {
        attachment: {
          content_type: string;
          media_url: string;
        };
        id: number;
        [key: string]: any;
      } | null = res.data.data;

      if (threadId && msgData) {
        threadStore.updateAsyncMsgThreadItem(
          "success",
          tempId,
          threadId.toString(),
          msgData.id.toString(),
          {
            dataType: "twitter",
            attachments: msgData.attachment ?? {},
          }
        );
      }
    },
    async deleteMsg(
      type: "sms" | "whatsapp" | "chat",
      id: string,
      inboxId: string,
      threadId: string
    ): Promise<void> {
      const threadStore = useThreadStore();
      const { threadsDetailMap } = storeToRefs(threadStore);
      const threadsData = threadsDetailMap.value[threadId];
      let res: AxiosResponse<any, any>;

      if (type === "sms" || type === "whatsapp") {
        const url = "/deleteScheduledMsg.php";

        const formData = new FormData();

        formData.append("mailboxId", inboxId);
        formData.append("messageId", id);
        formData.append("threadId", threadId);

        res = await $api.post(url, formData);
      } else {
        const url = "/chat-widget/deleteInboxMessage";

        const formData = new FormData();

        formData.append("mailboxID", inboxId);
        formData.append("messageID", id);
        formData.append("conversationID", threadId);

        res = await $api.post(url, formData);
      }

      const { status, message } = res.data;

      if (status.includes("error")) {
        throw new Error(message);
      }

      // Removing particular msg from the threadDetail.
      if (threadsData) {
        threadsData.threadItems = threadsData.threadItems.filter((i) => {
          if (
            i.type === "msg" &&
            (i.msgType === "sms" ||
              i.msgType === "whatsapp" ||
              i.msgType === "chat")
          ) {
            if (i.id === id) return false;
            return i;
          }

          return i;
        });
      }
    },
    async savePostDraft(
      inboxId: string,
      draftData: PostDraft,
      type: "insta" | "fb"
    ): Promise<{ draftId: number; threadId: number }> {
      let url = "/instagram/save-draft-v2.php";

      if (type === "fb") {
        url = "/fb-feed/save-draft-test.php";
      }

      const res = await $api.post(url, {
        ...draftData,
        mailboxID: parseInt(inboxId),
      });

      const { status, message, data } = res.data;

      if (status === "error") {
        throw new Error(message);
      }

      return {
        draftId: data.draftID,
        threadId: data.threadID,
      };
    },
    async sendPostComment(
      inboxId: string,
      data: Omit<PostDraft, "id">,
      type: "insta" | "fb" | "twitter"
    ) {
      let url = "/instagram/send_comment_v2.php";

      if (type === "fb") {
        url = "/fb-feed/reply-v2.php";
      } else if (type === "twitter") {
        url = "/send-tweet.php";
      }

      const res = await $api.post(url, {
        ...data,
        mailboxID: parseInt(inboxId),
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },
    async togglePostLikeStatus(
      id: number[],
      inboxId: string,
      type: "like" | "unlike",
      postType?: "twitter"
    ) {
      // TODO: This api has "api" after the facebook-integration
      // Backend need to fix it.
      const url =
        import.meta.env.VITE_BASE_URL +
        `twitter1/api/search/${
          type === "like" ? "like_tweet" : "unlike_tweet"
        }.php`;

      const res = await $api.post(url, {
        id,
        mailboxID: parseInt(inboxId),
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },

    async togglePostRetweetStatus(
      id: number,
      inboxId: string,
      type: "retweet" | "deleteTweet"
    ) {
      // TODO: This api has "api" after the facebook-integration
      // Backend need to fix it.
      const url =
        import.meta.env.VITE_BASE_URL +
        `/twitter1/api/search/${
          type === "retweet" ? "retweet_tweet" : "retweet_delete"
        }.php`;

      const res = await $api.post(url, {
        id,
        mailboxID: parseInt(inboxId),
      });

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },
    async startDiscussion(
      inboxId: string,
      msg: string,
      topic: string,
      participants: string[]
    ): Promise<void> {
      const url = "/startDiscussion.php";

      const res = await $api.post(url, {
        mailboxId: inboxId,
        message: msg,
        participants,
        topic,
      });

      const { status, message } = res.data;

      if (status.includes("error")) {
        throw new Error(message);
      }
    },
  },
});
