const useSound = (type: "receive") => {
  let soundUrl = "";

  if (type === "receive") {
    soundUrl = "https://app.helpwise.io/assets/sound/3.mp3";
  }

  const audio = new Audio(soundUrl);

  const playSound = () => {
    audio.play();
  };

  return {
    playSound,
  };
};

export default useSound;
