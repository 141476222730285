import $api from "@/api";
import { defineStore } from "pinia";
import type { State } from "./types/customInbox";
import type { ThreadCustomData } from "./types/thread";
import $apiv3 from "@/apiv3";

export const useCustomStore = defineStore({
  id: "custom",
  state: (): State => ({
    customContentMap: {},
    customDraftsMap: {},
  }),
  actions: {
    async fetchCustomContent(
      id: string,
      inboxId: string,
      type: "text" | "html"
    ): Promise<string> {
      const res = await $apiv3.get(
        `/inboxes/${inboxId}/customInboxMessages/${id}/content/${type}`
      );
      let content = "";
      if (res.data) {
        content = res.data;
        if (type === "text") {
          content = content.replace(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=;]*)/g,
            `<a href="$&" target="_blank">$&</a>`
          );
        }
        this.customContentMap[id] = {
          html: type === "html" ? content : null,
          text: type === "text" ? content : null,
        };
      }
      return content;
    },
    async fetchCustom(id: string, inboxId: string): Promise<ThreadCustomData> {
      const res = await $apiv3.get(`/inboxes/${inboxId}/custom/${id}`);
      return res.data;
    },
    async sendCustom(
      inboxId: string,
      customInboxData: object,
      isComposer: boolean,
      isClosed: boolean
    ): Promise<void> {
      const sendCustomMessageInput = {
        mailboxID: parseInt(inboxId),
        archive: isClosed,
        subject: customInboxData["subject"],
        text: customInboxData["text"],
        html: customInboxData["html"],
        to: customInboxData["to"],
        custom: customInboxData["custom"],
      };
      if (!isComposer) {
        sendCustomMessageInput["threadID"] = parseInt(
          customInboxData["threadID"]
        );
      }

      const res = await $api.post(
        "/send_custom_inbox.php",
        sendCustomMessageInput
      );

      const { status, message } = res.data;

      if (status === "error") {
        throw new Error(message);
      }
    },
  },
});
