import $api from "@/api";
import { defineStore, storeToRefs } from "pinia";
import { useThreadStore } from "./thread";
import type { State, Tag } from "./types/tag";
import type { ThreadTag } from "./types/thread";

export const useTagStore = defineStore({
  id: "tag",
  state: (): State => ({
    tags: null,
    pinnedInboxTags: null,
    nestedTags: [],
    pinnedNestedTags: null,
    tagPage: 1,
    moreTags: true,
    advSearchInboxTagMap: {},
  }),
  actions: {
    async fetchTags(
      inboxIds: string[],
      page: number,
      searchStr?: string,
      threadIds?: number[],
      getNested?: boolean
    ): Promise<Tag[] | void> {
      if (this.moreTags || searchStr) {
        const res = await $api.get("/tags/list.php", {
          params: {
            mailboxIds: inboxIds,
            search: searchStr,
            threadIds: threadIds,
            is_unified: 1,
            page,
            limit: 100,
          },
        });

        const { status, data, message } = res.data;

        if (status.includes("error")) {
          throw new Error(message);
        }
        if (searchStr) {
          return data.tags;
        }

        if (this.tags) {
          this.tags = this.tags.concat(data.tags);
          this.nestedTags =
            this.nestedTags?.concat(data.nestedTags) || data.nestedTags;
        } else {
          this.tags = data.tags;
          this.nestedTags = data.nestedTags;
        }
        if (this.pinnedNestedTags) {
          this.pinnedNestedTags =
            this.pinnedNestedTags?.concat(data.nestedTags) || data.nestedTags;
        } else {
          this.pinnedNestedTags = data.nestedTags;
        }
        this.moreTags = data.more;
      }
    },
    async fetchTagsForAdvSearch(inboxIds: string[]): Promise<void> {
      const res = await $api.get("/tags/list.php", {
        params: {
          mailboxIds: inboxIds,
          is_unified: 1,
          page: 1,
          limit: 100000,
        },
      });

      const { status, data, message } = res.data;

      if (status.includes("error")) {
        throw new Error(message);
      }

      if (inboxIds.length > 0) {
        this.advSearchInboxTagMap[inboxIds[0]] = data.tags;
      }
    },
    async fetchTagById(
      inboxIds: string[],
      tagId: number
    ): Promise<Tag[] | void> {
      const res = await $api.get("/tags/list.php", {
        params: {
          mailboxIds: inboxIds,
          tagId,
          is_unified: 1,
        },
      });

      const { status, data, message } = res.data;

      if (status.includes("error")) {
        throw new Error(message);
      }

      if (this.pinnedNestedTags) {
        this.pinnedNestedTags = this.pinnedNestedTags.concat(data.nestedTags);
      } else {
        this.pinnedNestedTags = data.pinnedNestedTags;
      }
    },
    async fetchPinnedTags(inboxIds: string[]): Promise<string[] | void> {
      const res = await $api.get("/tags/list.php", {
        params: {
          mailboxIds: inboxIds,
          is_pinned: true,
        },
      });

      const { status, data, message } = res.data;

      if (status.includes("error")) {
        throw new Error(message);
      }
      const tags: Tag[] = data.tags;

      this.pinnedInboxTags = tags;
    },
    async pinTags(inboxId: number, tagId: number): Promise<void> {
      const res = await $api.get("/pin-tag-sidebar.php", {
        params: {
          mailboxId: inboxId,
          tagId: tagId,
        },
      });
      const { status, message } = res.data;

      if (status.includes("error")) {
        throw new Error(message);
      }
    },
    async createTag(
      name: string,
      color: string,
      inboxId?: string
    ): Promise<{ id: number }> {
      const res = await $api.post("/tags/create.php", {
        mailboxId: inboxId ? inboxId : "204376",
        name,
        color,
        is_unified: true,
      });

      const { status, data, message } = res.data;

      if (status.includes("error")) {
        throw new Error(message);
      }

      return {
        id: data.id,
      };
    },
    async updateTags(
      tagToAdd: Tag[],
      tagToRemove: Tag[],
      /**
       * inbox and thread map to extend the logic for universal inbox.
       * { [inboxId]: threadId[] }
       */
      threadIdsMap: {
        [inboxId: string]: number[];
      }
    ): Promise<void> {
      if (!this.tags) return;

      let threadIds: number[] = [];
      const threadStore = useThreadStore();
      const { threadsDetailMap, threads } = storeToRefs(threadStore);

      try {
        // Updating tag checked status
        this.tags = this.tags.map((t) => {
          let checked = t.checked;

          const foundTagAdd = tagToAdd.find((tag) => tag.id === t.id);
          const foundTagRemove = tagToRemove.find((tag) => tag.id === t.id);

          if (foundTagAdd) {
            checked = true;
          }

          if (foundTagRemove) {
            checked = false;
          }

          return {
            ...t,
            checked,
          };
        });

        // Updating thread tags
        Object.values(threadIdsMap).forEach((t) => {
          threadIds = threadIds.concat(t);
        });

        // Find the thread in threadDetailMap and update the thread first there
        threadIds.forEach((id) => {
          const threadDetail = threadsDetailMap.value[id]?.threadDetail;
          const threadItem = threads.value.find((t) => t.id === id);

          tagToAdd.forEach((t) => {
            const newTag: ThreadTag = {
              color: t.color,
              id: t.id,
              name: t.name,
            };

            if (threadItem) {
              const foundTag = threadItem.tags.find((tag) => tag.id === t.id);
              if (!foundTag) {
                threadItem.tags.push(newTag);
              }
            }
            if (threadDetail) {
              // Add the new tags
              const foundTag = threadDetail.tags.find((tag) => tag.id === t.id);
              if (!foundTag) {
                threadDetail.tags.push(newTag);
              }
            }
          });

          tagToRemove.forEach((t) => {
            if (threadDetail) {
              threadDetail.tags = threadDetail.tags.filter(
                (tag) => tag.id !== t.id
              );
            }
            if (threadItem) {
              threadItem.tags = threadItem?.tags.filter(
                (tag) => tag.id !== t.id
              );
            }
          });
        });

        const tagIdsToAdd = tagToAdd.map((tag) => tag.id);
        const tagIdsToRemove = tagToRemove.map((tag) => tag.id);

        const res = await $api.post("/unifiedv2/tags/applyTags.php", {
          addTags: tagIdsToAdd,
          removeTags: tagIdsToRemove,
          mailboxThreadMap: threadIdsMap,
        });

        const { status } = res.data;

        if (status === "error") {
          throw new Error();
        }
      } catch (err) {
        // Updating tag checked status
        this.tags = this.tags.map((t) => {
          let checked = t.checked;

          const foundTagAdd = tagToAdd.find((tag) => tag.id === t.id);
          const foundTagRemove = tagToRemove.find((tag) => tag.id === t.id);

          if (foundTagAdd) {
            checked = false;
          }

          if (foundTagRemove) {
            checked = true;
          }

          return {
            ...t,
            checked,
          };
        });

        // TODO: Rollback the threads

        throw err;
      }
    },
  },
});
