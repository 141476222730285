import axios from "axios";

/* eslint-disable-next-line */
const $api = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
  headers: {
    Cookie: document.cookie,
  },
  withCredentials: true,
});

$api.interceptors.response.use(
  (response) => response,
  (error) => {
    const baseUrl = import.meta.env.VITE_BASE_URL;

    if (error.response?.data?.data?.trialExpired) {
      window.location.href = baseUrl + "upgrade";

      return;
    }
    if (error.response?.status === 401) {
      window.location.href = baseUrl + "login";
    } else {
      throw error;
    }
  }
);

export default $api;
