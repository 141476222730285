import $api from "@/api";
import { db, parentRef } from "@/firebase";
import { ref, remove, set, onDisconnect } from "@firebase/database";
import { defineStore, storeToRefs } from "pinia";
import type { ComponentPublicInstance, defineAsyncComponent } from "vue";
import type { ReplierAttachment } from "./types/email";
import type {
  ConversationListOrder,
  InboxVerficationCode,
  State,
} from "./types/root";
import { useUserStore } from "./user";
import dayjs from "dayjs";
import { IsSeen } from "./enums/isSeen.enum";
import useAxiosController from "@/functions/useAxiosController";
import { useInboxStore } from "./inbox";

const { createReqController, cancelReq } = useAxiosController();

declare type AttachmentFor =
  | "default"
  | "whatsapp"
  | "dialog360"
  | "whatsapp-cloud"
  | "facebook"
  | "sms"
  | "instagram-dm"
  | "chat"
  | "instagram-comments"
  | "facebook-comments"
  | "twitter"
  | "twitterdm";

interface UploadAttachmentOptions {
  inboxId: string;
  file: File;
  progressId: number;
  attachmentFor: AttachmentFor;
  draftId?: number;
  isInline?: boolean;
}

export const useRootStore = defineStore({
  id: "root",
  state: (): State => ({
    initFirebasePromise: null,
    modal: null,
    drawer: null,
    uploadProgress: {},
    pinnedUniversalInboxes: [],
    pinnedUniversalTags: [],
    newNotificationCount: 0,
    notifications: null,
    typingUsers: {},
    replyingUsers: {},
    routeKey: 0,
    productUpdates: {
      isSeen: IsSeen.notSeen,
      list: [],
    },
    timezones: [],
    conversationListOrder:
      (localStorage.getItem(
        "conversationListOrder"
      ) as ConversationListOrder | null) ?? "newest",
    conversationListOrder2:
      (localStorage.getItem(
        "conversationListOrder2"
      ) as ConversationListOrder | null) ?? "newest",
    newReleaseToastId: null,
  }),
  actions: {
    async testInboxForwarding(inboxId: string) {
      const res = await $api.post("/startForwardingTest.php", {
        mailboxId: inboxId,
      });

      const { status } = res.data;

      if (status.includes("error")) {
        throw new Error("Couldn't test inbox forwarding. Please try again.");
      }
    },

    updateConversationListOrder(conversationListOrder: ConversationListOrder) {
      localStorage.setItem("conversationListOrder", conversationListOrder);
      this.conversationListOrder = conversationListOrder;
    },

    updateConversationListOrder2(conversationListOrder: ConversationListOrder) {
      localStorage.setItem("conversationListOrder2", conversationListOrder);
      this.conversationListOrder2 = conversationListOrder;
    },

    async verifyInboxForwarding(
      inboxId: string
    ): Promise<{ code: InboxVerficationCode }> {
      const inboxStore = useInboxStore();
      const { inboxes } = storeToRefs(inboxStore);

      const url = `/getForwardingStatus.php`;

      // First cancel the previous request if happening
      cancelReq(url);
      // create new request controller for axios
      const controller = createReqController(url);

      const res = await $api.get(url, {
        params: {
          mailboxId: inboxId,
        },
        signal: controller.signal,
      });

      const { status, data } = res.data;

      if (status.includes("error")) {
        throw new Error("Couldn't verify inbox forwarding. Please try again.");
      }

      const { code } = data;

      if (code === "verified") {
        const foundInbox = inboxes.value.find(
          (i) => i.id.toString() === inboxId
        );

        if (foundInbox?.universalSettings) {
          foundInbox.universalSettings.forwarding = true;
        }
      }

      return { code };
    },
    async testDmarcStatus(inboxId: string) {
      const res = await $api.post("/mailgun/validateDmarc.php", {
        mailboxId: inboxId,
      });

      const { status } = res.data;

      if (status.includes("error")) {
        throw new Error("Couldn't validate dmarc status. Please try again.");
      }
    },
    /**
     * @param progressId - Id for mapping progress for multiple progress loader
     */
    async uploadAttachment(
      options: UploadAttachmentOptions
    ): Promise<ReplierAttachment> {
      const formData = new FormData();

      const { inboxId, file, progressId, attachmentFor, draftId, isInline } =
        options;
      formData.append("files[]", file);
      formData.append("mailboxID", inboxId);

      if (draftId) {
        formData.append("emailID", draftId.toString());
      }

      let url = "/uploadAttachment.php";

      formData.append("isInline", isInline ? "true" : "false");

      if (attachmentFor !== "default") {
        formData.append("type", "public");
      }

      if (attachmentFor === "facebook") {
        url =
          import.meta.env.VITE_BASE_URL +
          "facebook-integration/api/attachment_upload.php";
      } else if (attachmentFor === "sms") {
        url = "/sms/aws_upload.php";
      } else if (attachmentFor === "dialog360") {
        url = "/dialog360/aws_upload.php";
      } else if (attachmentFor === "whatsapp-cloud") {
        url = "/whatsappCloud/aws_upload.php";
      } else if (attachmentFor === "whatsapp") {
        url = "/aws_upload.php";
      } else if (attachmentFor === "instagram-dm") {
        url = "/instagram-dm/attachmentUpload.php";
      } else if (attachmentFor === "chat") {
        url = "/chat-widget/aws_upload.php";
      } else if (attachmentFor === "twitter") {
        url = "/upload-tweet-media.php";
      } else if (attachmentFor === "twitterdm") {
        formData.append("type", "public");
        url = "/twitter-dm/aws.php";
      }
      const res = await $api.post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          this.uploadProgress[progressId] = percentCompleted;
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error("Couldn't upload the file. Please try again.");
      }

      let contentType = "";
      if (data.files[0] && data.files[0].contentType) {
        contentType = data.files[0].contentType;
      }
      return {
        id: data.files[0].id ?? data.data[0].id ?? data.files[0],
        extension: data.files[0].extension ?? data.data[0].extension,
        filehash: data.files[0].filehash ?? data.data[0].filehash,
        filename: data.files[0].filename ?? data.data[0].filename,
        filesize: data.files[0].filesize ?? data.data[0].filesize,
        contentType: contentType,
      };
    },
    /**
     * Mounts a modal to modal target.
     */
    setModal<T extends ComponentPublicInstance>(
      payload: {
        component: ReturnType<typeof defineAsyncComponent>;
        props: T["$props"];
      } | null
    ) {
      this.modal = payload;
    },
    /**
     * Mounts a Drawer to drawer target.
     */
    setDrawer<T extends ComponentPublicInstance>(
      payload: {
        component: ReturnType<typeof defineAsyncComponent>;
        props: T["$props"];
      } | null
    ) {
      this.drawer = payload;
    },
    async setUniversalPins(): Promise<void> {
      const res = await $api.post("/setUserSidebarPins.php", {
        pins: {
          inboxes: this.pinnedUniversalInboxes,
          tags: this.pinnedUniversalTags,
        },
      });

      const { status } = res.data;

      if (status.includes("error")) {
        throw new Error("Couldn't pin the selected item. Please try again.");
      }
    },
    async fetchProductUpdates() {
      const res = await $api.get("/productUpdates/list.php");

      const { status, data } = res.data;

      if (status.includes("error")) {
        throw new Error("Couldn't get the product updates. Please try again.");
      }

      this.productUpdates.isSeen = data.isSeen;
      this.productUpdates.list = data.productUpdates;
    },
    async searchProductUpdates(query: string) {
      const res = await $api.get("/productUpdates/search.php", {
        params: {
          query,
        },
      });

      const { status, data } = res.data;

      if (status.includes("error")) {
        throw new Error("Couldn't get the product updates. Please try again.");
      }

      this.productUpdates.list = data.searchResults;
    },
    /**
     * Marks the update as read.
     */
    async readProductUpdates() {
      const res = await $api.post("/productUpdates/read.php");

      const { status } = res.data;

      if (status.includes("error")) {
        throw new Error(
          "Couldn't update the product updates. Please try again."
        );
      }
    },
    async fetchNotification() {
      const res = await $api.get("/get-notifications.php");

      const { status, data } = res.data;

      if (status.includes("error")) {
        throw new Error("Couldn't get the notifications. Please try again.");
      }

      this.notifications = data.notifications;
      this.newNotificationCount = data.unreadNotificationsCount;
    },
    async readAllNotification() {
      const res = await $api.get("/read-all-notifications.php");

      const { status } = res.data;

      if (status.includes("error")) {
        throw new Error("Couldn't read the notifications. Please try again.");
      }

      this.newNotificationCount = 0;
    },
    async toggleUserTyping(
      threadId: number | string,
      type: "commenting" | "replying",
      showTyping: boolean,
      /**
       * If passed, this userId will be used instead of logged in user id.
       */
      userId?: string
    ) {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      const dbRef = ref(
        db,
        parentRef +
          `/ThreadID-${threadId}/${
            type === "commenting" ? "commenting user" : "replying user"
          }/user-${userId ?? user.id}`
      );

      if (showTyping) {
        set(dbRef, {
          ...user,
          time: dayjs().utc().valueOf(),
        });

        onDisconnect(dbRef).remove();
      } else {
        remove(dbRef);
      }
    },
    async toggleUserEmailReplying(
      inboxId: number | string,
      threadId: number | string,
      showTyping: boolean,
      /**
       * If passed, this userId will be used instead of logged in user id.
       */
      userId?: string
    ) {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      const dbRef = ref(
        db,
        parentRef +
          `/Mailbox-${inboxId}` +
          `/Thread-${threadId}/replying user/${userId ?? user.id}`
      );
      if (showTyping) {
        set(dbRef, {
          ...user,
          displayData: {
            name: user.firstname + " " + user.lastname,
          },
          time: dayjs().utc().valueOf(),
        });
        onDisconnect(dbRef).remove();
      } else {
        remove(dbRef);
      }
    },
    async getTimezones() {
      const res = await $api.get("/get-timezones.php");

      const { status, data } = res.data;

      if (status.includes("error")) {
        throw new Error("Couldn't get the timezones. Please try again.");
      }

      this.timezones = data.timezones;
    },
    setNewReleaseToastId(toastId: string | null) {
      this.newReleaseToastId = toastId;
    },
  },
});
