<template>
  <HWMenu
    arrow
    content-styles="padding: 0; min-width: 298px"
    placement="bottom-end"
  >
    <template #trigger>
      <button
        class="btn-icon"
        style="position: relative"
        @click="handleBellClick"
      >
        <mdicon name="bell-outline" size="24"></mdicon>
        <span
          v-if="newNotificationCount && newNotificationCount > 1000"
          class="new-notif-indicator"
        >
          1k<span style="position: relative; top: -1px">+</span>
        </span>
        <span v-else-if="newNotificationCount > 0" class="new-notif-indicator">
          {{ newNotificationCount }}
        </span>
      </button>
    </template>
    <template #content="{ closeMenu }">
      <div
        style="border-bottom: 1px solid rgba(72, 94, 144, 0.16)"
        class="dropdown-header d-flex align-items-center justify-content-between"
      >
        <span
          style="
            font-weight: bold;
            font-size: 12px;
            color: #1b2e4b;
            text-transform: uppercase;
            letter-spacing: 0.5px;
          "
        >
          Notifications
        </span>
        <a
          :href="getAbsoluteUrl('/notification-center')"
          style="color: inherit"
        >
          <mdicon name="cog" size="17"></mdicon>
        </a>
      </div>
      <div
        id="notifications-list"
        class="py-1 px-1"
        style="max-height: 450px; overflow-y: auto"
      >
        <template v-if="loading">
          <div
            class="dropdown-item no-bg d-flex align-items-center"
            style="height: 55px"
          >
            <Skeletor
              pill
              style="max-height: 13px; max-width: 230px; margin: 0 auto"
            />
          </div>
        </template>

        <template v-else-if="notifications && !loading">
          <RouterLink
            v-for="(noti, i) in notifications"
            :key="i"
            :to="getNotificationLink(noti)"
            class="dropdown-item"
            style="padding: 10px 15px"
            @click="closeMenu"
          >
            <div class="media">
              <div class="media-body" style="max-width: 100%">
                <div class="justify-content-between">
                  <span
                    class="badge badge-pill"
                    style="color: white; font-size: 9px"
                    :style="{
                      backgroundColor: calcDisplayColor(noti.mailboxID),
                    }"
                  >
                    {{
                      inboxesMap[noti.mailboxID]?.displayName ??
                      noti.mailboxType
                    }}
                  </span>
                  <div class="text-truncate tx-bold">
                    {{ noti.text ? noti.text : "-" }}
                  </div>
                </div>
                <p
                  class="tx-12 tx-color-03"
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 2px;
                  "
                >
                  {{ noti.title }}
                </p>
                <span style="color: #8392a5; font-size: 11px">
                  {{ dayjs(noti.time).tz().format("MMM DD, YYYY hh:mm a") }}
                </span>
              </div>
            </div>
          </RouterLink>
        </template>
      </div>
    </template>
  </HWMenu>
</template>

<script lang="ts" setup>
import { useInboxStore } from "@/stores/inbox";
import { useRootStore } from "@/stores/root";
import type { Notification } from "@/stores/types/root";
import { getAbsoluteUrl } from "@/utils/helperFunction";
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

const rootStore = useRootStore();
const inboxStore = useInboxStore();

const { isInboxUniversal, inboxesMap } = storeToRefs(inboxStore);
const { notifications, newNotificationCount } = storeToRefs(rootStore);

const loading = ref(false);

// From old code base
const stringToHslColor = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};

const getNotificationLink = (notification: Notification): string => {
  if (isInboxUniversal.value) {
    return `/universal/me/all/${notification.threadID}`;
  } else {
    return `/inboxes/${notification.mailboxID}/all/${notification.threadID}`;
  }
};

const calcDisplayColor = (id) => {
  return stringToHslColor(btoa(id) + "", 32, 64);
};

const handleBellClick = async () => {
  rootStore.readAllNotification();
  // clear the notification count
  newNotificationCount.value = 0;
};
</script>

<style scoped>
.dropdown-item.no-bg {
  background: transparent !important;
  cursor: auto;
}

.new-notif-indicator {
  display: flex;
  width: 15px;
  height: 15px;
  justify-content: center;
  position: absolute;
  top: -3px;
  right: -2px;
  background-color: #dc3545;
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  font-family: sans-serif;
  line-height: 2;
  border-radius: 100%;
}
</style>
