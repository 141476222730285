<template>
  <div class="d-flex align-items-center justify-content-between">
    <div>
      Our team have released a new version to our app. Please reload the page to
      update it to the latest version
    </div>
    <button class="btn btn-xs btn-success" @click="onReloadClick">
      Reload
    </button>
  </div>
</template>

<style scoped>
.undo-txt:hover {
  text-decoration: underline;
}
</style>

<script lang="ts" setup>
const onReloadClick = () => {
  location.reload();
};
</script>
