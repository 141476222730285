const useAxiosController = () => {
  const controllers = new Map<string, AbortController>();

  const cancelReq = (url: string) => {
    if (controllers.has(url)) {
      controllers.get(url)?.abort();
      controllers.delete(url);
    }
  };

  const createReqController = (url: string) => {
    const controller = new AbortController();
    controllers.set(url, controller);

    return controller;
  };

  return { createReqController, cancelReq };
};

export default useAxiosController;
