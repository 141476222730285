import axios from "axios";

const $apiv3 = axios.create({
  baseURL: import.meta.env.VITE_BASE_APIV3_URL,
  headers: {
    Cookie: document.cookie,
  },
  withCredentials: true,
});

$apiv3.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 404 && error.response?.data?.location) {
      const location: string = error["response"]["data"]["location"];
      window.location.href = location;
    }
    if (error.response?.status === 401) {
      const baseUrl = import.meta.env.VITE_BASE_URL;
      // TODO - redirection to logic page
      window.location.href = baseUrl + "login";
    } else {
      throw error;
    }
  }
);

export default $apiv3;
