import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import mixpanel from "mixpanel-browser";

// Lib components
import Toast, {
  type PluginOptions,
  POSITION,
  useToast,
} from "vue-toastification";
import { Skeletor } from "vue-skeletor";
import Datepicker from "@vuepic/vue-datepicker";
import Multiselect from "@vueform/multiselect";
import mdiVue from "mdi-vue/v3";
import * as mdijs from "@mdi/js";
import VueTippy from "vue-tippy";
import "@fontsource/inter";
import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";

import $ from "jquery";

import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";

import "@vuepic/vue-datepicker/dist/main.css";
import "vue-toastification/dist/index.css";
import "vue-skeletor/dist/vue-skeletor.css";
import "@vueform/multiselect/themes/default.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/material.css";
import "tippy.js/dist/tippy.css";
import "vue3-tel-input/dist/vue3-tel-input.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min";
import "froala-editor/js/third_party/font_awesome.min";
import "froala-editor/js/third_party/spell_checker.min";
import "froala-editor/js/third_party/image_tui.min";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";

// Custom lib components
import VueFroala from "vue-froala-wysiwyg";
import HWModal from "@/components/partials/lib/HWModal.vue";
import HWChip from "@/components/partials/lib/HWChip.vue";
import HWDrawer from "@/components/partials/lib/HWDrawer.vue";
import HWMenu from "./components/partials/lib/HWMenu.vue";

import App from "./App.vue";
import router from "./router";
import "./api";
import dayjs from "dayjs";
import { chatLocaleObject } from "./locale/dayjs/chat.config";
import HWSearchInput from "./components/partials/lib/HWSearchInput.vue";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("chat", chatLocaleObject, true);

if (import.meta.env.VITE_MIX_PANEL_TOKEN) {
  mixpanel.init(import.meta.env.VITE_MIX_PANEL_TOKEN);
}

const app = createApp(App);

app.use(VueFroala);
app.use(mdiVue, {
  icons: mdijs,
});
app.use(createPinia());
app.use(router);

app.use(Toast, {
  position: POSITION.TOP_RIGHT,
} as PluginOptions);

app.use(VueTippy, {
  directive: "tippy", // => v-tippy
  defaultProps: {
    placement: "auto-end",
    allowHTML: true,
    arrow: false,
    theme: "material",
  },
});

app.component("Skeletor", Skeletor);
app.component("DatePicker", Datepicker);
app.component("MultiSelect", Multiselect);

app.component("HWModal", HWModal);
app.component("HWChip", HWChip);
app.component("HWMenu", HWMenu);
app.component("HWDrawer", HWDrawer);
app.component("HWSearchInput", HWSearchInput);

// # Error handler
// TODO: need to send console error message from every component to this global handler
app.config.errorHandler = (err, instance, info) => {
  const toast = useToast();
  const error = err as Error;
  // If message is being sent while throwing error
  // Show that else show the default msg.
  console.log(error);
  const errorMessage: string | undefined =
    error["response"]?.["data"]?.["message"];

  const msg =
    errorMessage ??
    error.message ??
    "Something went wrong, please contact customer support.";

  toast.error(msg);
  console.error(err, instance, info);

  return false;
};

if (import.meta.env.MODE == "production" || import.meta.env.MODE == "staging") {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        tracePropagationTargets: ["helpwise"],
      }),
      Sentry.breadcrumbsIntegration({
        console: import.meta.env.MODE === "production",
      }),
    ],
    tracesSampleRate: 0.05,
    sampleRate: 0.3,
    // set this according to current environment
    environment: import.meta.env.MODE,
  });
}

app.mount("#app");
