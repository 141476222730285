<template>
  <a
    :href="link ? link : undefined"
    target="_blank"
    class="attachment-badge badge badge-pill"
    :draggable="draggable"
    :class="{
      'attachment-badge-outline': chipType === 'outline',
      'progress-100': progressWidth === '100%',
    }"
  >
    <!-- Icons -->
    <div v-if="showExtension || icon" class="mg-r-3">
      <span
        v-if="showExtension"
        :style="{
          height: iconSize || '16px',
          width: iconSize || '16px',
        }"
        class="fiv-viv"
        :class="'fiv-icon-' + showExtension.extension"
      ></span>
      <mdicon v-else-if="icon" :size="iconSize ?? 14" :name="icon" />
    </div>

    <!-- Content -->
    <span
      :class="{ 'mg-r-4': clossable }"
      :style="{
        color: fontColor,
      }"
      class="d-flex align-items-center"
      style="overflow: hidden"
    >
      <slot></slot>
    </span>

    <!-- Action  -->
    <span
      :style="{
        color: fontColor,
      }"
    >
      <mdicon
        v-if="clossable"
        style="cursor: pointer; margin-left: auto"
        size="13"
        name="close"
        @click.stop="$emit('close')"
        @click.prevent
      />
    </span>
  </a>
</template>

<script lang="ts" setup>
import { addAlpha } from "@/utils/helperFunction";
import { computed } from "vue";

const props = defineProps<{
  /**
   * Supports hex only
   */
  color: string;
  fontColor?: string;
  chipType?: "default" | "outline";
  /**
   * Should have % at the end.
   */
  progressWidth?: string;

  /**
   * Supports mdicon
   */
  icon?: string;
  iconSize?: string | number;
  clossable?: boolean;
  /**
   * If showExtension prop is passed icon props gets overide
   */
  showExtension?: {
    extension: string;
  };
  link?: string;
  draggable?: boolean;
}>();

defineEmits<{
  (e: "close"): void;
}>();

const progressionWidth = computed(() => {
  return props.progressWidth ?? "100%";
});

const chipColorLight = computed(() => {
  return addAlpha(props.color, 0.5);
});

const chipColorOutlineLight = computed(() => {
  return addAlpha(props.color, 0.1);
});
</script>

<style scoped>
.attachment-badge {
  background: v-bind(chipColorLight);
  color: #fff !important;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: fit-content;
}

.attachment-badge::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: v-bind(progressionWidth);
  height: 100%;
  background: v-bind(color);
  z-index: -1;
  border-radius: inherit;
}

.attachment-badge:hover::before {
  background: v-bind(chipColorLight);
}

.attachment-badge-outline {
  border: 1px solid #ccc;
  background: #fff;
  color: #333 !important;
}

.attachment-badge-outline::before {
  background: v-bind(chipColorOutlineLight);
}

.attachment-badge-outline:hover::before {
  background: #fff;
}

.attachment-badge-outline.progress-100::before {
  background: #fff;
}

.attachment-badge-outline.progress-100:hover::before {
  background: v-bind(chipColorOutlineLight);
}
</style>
