import { useMagicKeys, useActiveElement, whenever } from "@vueuse/core";
import useAutoAssigner from "@/functions/useAutoAssigner";
import { useRoute, useRouter, type LocationQueryRaw } from "vue-router";
import { useInboxStore } from "@/stores/inbox";
import { storeToRefs } from "pinia";
import type { InboxLabels } from "@/stores/types/inbox";
import useCompose from "./useCompose";
import { computed, onMounted, ref } from "vue";
import useMitt from "./useMitt";

const useShortcuts = () => {
  const route = useRoute();
  const router = useRouter();
  const inboxStore = useInboxStore();
  const { emitter } = useMitt();

  const {
    alt_a,
    g_i,
    g_u,
    alt_g_a,
    g_s,
    alt_g_s,
    g_m,
    g_d,
    cmd_g_i,
    cmd_shift_o,
    ctrl_shift_o,
    ctrl_g_i,
    g_p,
    g_c,
    alt_g_p,
    g_t,
    cmd_shift_c,
    ctrl_shift_c,
    alt_left,
    alt_right,
    alt_t,
    alt_þ, // for alt_t
  } = useMagicKeys();
  const activeEl = useActiveElement();
  const { autoAssign } = useAutoAssigner();
  const { openCompose } = useCompose();

  const { isInboxUniversal, inbox } = storeToRefs(inboxStore);

  const page = ref(1);

  const disableShortcuts = computed(() => {
    return (
      activeEl.value?.className.includes("ProseMirror") ||
      activeEl.value?.tagName === "INPUT" ||
      activeEl.value?.tagName === "TEXTAREA" ||
      activeEl.value?.className.includes("fr-element")
    );
  });

  // Need to make it alt then a
  whenever(alt_a, () => {
    if (disableShortcuts.value) return;
    assignYourself();
  });

  whenever(g_i, () => {
    if (disableShortcuts.value) return;
    changeRoute("assigned");
  });

  whenever(g_u, () => {
    if (disableShortcuts.value) return;
    changeRoute("unassigned");
  });

  whenever(alt_g_a, () => {
    if (disableShortcuts.value) return;
    changeRoute("mentions");
  });

  whenever(g_s, () => {
    if (disableShortcuts.value) return;
    changeRoute("sent");
  });

  whenever(alt_g_s, () => {
    if (disableShortcuts.value) return;
    changeRoute("snoozed");
  });

  whenever(g_m, () => {
    if (disableShortcuts.value) return;
    changeRoute("mine");
  });

  whenever(g_d, () => {
    if (disableShortcuts.value) return;
    changeRoute("drafts");
  });

  whenever(cmd_g_i, () => {
    if (disableShortcuts.value) return;
    changeRoute("starred");
  });

  whenever(ctrl_g_i, () => {
    if (disableShortcuts.value) return;
    changeRoute("starred");
  });

  whenever(g_p, () => {
    if (disableShortcuts.value) return;
    changeRoute("scheduled");
  });

  whenever(g_c, () => {
    if (disableShortcuts.value) return;
    changeRoute("closed");
  });

  whenever(alt_g_p, () => {
    if (disableShortcuts.value) return;
    changeRoute("spam");
  });

  whenever(g_t, () => {
    if (disableShortcuts.value) return;
    changeRoute("trash");
  });

  whenever(cmd_shift_c, () => {
    if (disableShortcuts.value) return;
    openCompose();
  });

  whenever(ctrl_shift_c, () => {
    if (disableShortcuts.value) return;
    openCompose();
  });

  whenever(alt_left, () => {
    if (disableShortcuts.value) return;
    changePage("prev");
  });

  whenever(alt_right, () => {
    if (disableShortcuts.value) return;
    changePage("next");
  });

  whenever(alt_t, () => {
    if (disableShortcuts.value) return;
    deleteThread();
  });

  whenever(alt_þ, () => {
    if (disableShortcuts.value) return;
    deleteThread();
  });

  whenever(cmd_shift_o, () => {
    if (disableShortcuts.value) return;
    uncollapseEmails();
  });

  whenever(ctrl_shift_o, () => {
    if (disableShortcuts.value) return;
    uncollapseEmails();
  });

  onMounted(() => {
    // disabling default browser actions on it.
    window.addEventListener("keydown", (e) => {
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.code === "KeyC") {
        e.preventDefault();
      }

      if (
        (e.ctrlKey || e.metaKey) &&
        (e.code === "KeyG" || e.code === "KeyI")
      ) {
        e.preventDefault();
      }

      // @bugfix: alt + C not working well with magicKey
      if (e.altKey && e.code === "KeyC") {
        if (disableShortcuts.value) return;
        closeThread();
      }
    });

    // Populate page count if present
    const currentPage = route.query.page as string | undefined;
    if (currentPage) {
      page.value = parseInt(currentPage);
    }
  });

  const deleteThread = () => {
    emitter.emit("use-shortcut:trigger", {
      shortcutFor: "deleteThread",
    });
  };

  const closeThread = () => {
    emitter.emit("use-shortcut:trigger", {
      shortcutFor: "closeThread",
    });
  };

  const uncollapseEmails = () => {
    emitter.emit("use-shortcut:trigger", {
      shortcutFor: "uncollapseEmails",
    });
  };

  const assignYourself = () => {
    const threadId = route.params.threadId as string;
    const inboxId = route.params.id as string;

    if (!threadId) return;

    autoAssign(inboxId, threadId, true);
  };

  const changeRoute = (label: InboxLabels) => {
    let _label = label;
    const query: LocationQueryRaw = {};

    if (isInboxUniversal.value) {
      if (["sent", "closed", "snoozed"].includes(label)) {
        query.filter = label;
        _label = "all";
      } else {
        query.filter = "open";
      }
    }

    // only open draft in single mail inbox
    if (
      _label === "drafts" &&
      (inbox.value?.type !== "mail" || isInboxUniversal.value)
    ) {
      return;
    }

    router.push({
      name: isInboxUniversal.value ? "universal" : "single",
      query,
      params: {
        label: _label,
        threadId: null,
      },
    });
  };

  const changePage = (type: "next" | "prev") => {
    type === "next" ? page.value++ : page.value--;

    router.push({
      name: isInboxUniversal.value ? "universal" : "single",
      query: {
        ...route.query,
        page: page.value,
      },
    });
  };
};

export default useShortcuts;
