<template>
  <HWMenu
    placement="bottom-end"
    :menu-items="helpMenuItems"
    list-container-styles="width: 190px;"
    arrow
    list-styles="cursor: pointer; padding: 0px; display: flex"
  >
    <template #trigger>
      <button class="btn-icon">
        <mdicon name="apps" size="24"></mdicon>
      </button>
    </template>
    <template #menu-item="{ item }">
      <a
        style="color: inherit; font-size: 14px; padding: 10px 15px"
        :href="item.value.link"
        target="_blank"
        class="menu-item"
      >
        <mdicon :name="item.value.icon" size="20" class="mr-2"></mdicon>
        {{ item.text }}
      </a>
    </template>
  </HWMenu>
</template>

<script lang="ts" setup>
import { useDialerStore } from "@/stores/dialer";
import { onMounted, ref } from "vue";

const helpMenuItems = ref<
  {
    text: string;
    value: {
      id?: string;
      icon: string;
      link: string;
    };
  }[]
>([
  {
    text: "App Store",
    value: {
      icon: "apple",
      link: "https://apps.apple.com/us/app/helpwise/id1503985272",
    },
  },
  {
    text: "Play Store",
    value: {
      icon: "google-play",
      link: "https://play.google.com/store/apps/details?id=com.saaslabs.helpwise",
    },
  },
]);

const { getDialerLinks } = useDialerStore();

onMounted(async () => {
  const dialerLinks = await getDialerLinks();
  helpMenuItems.value.push({
    text: `Dialer (${dialerLinks.mac.version})`,
    value: {
      icon: "apple",
      link: dialerLinks.mac.link,
    },
  });
  helpMenuItems.value.push({
    text: `Dialer (${dialerLinks.mac.version})`,
    value: {
      icon: "microsoft-windows",
      link: dialerLinks.windows.link,
    },
  });
  helpMenuItems.value.push({
    text: `Dialer (${dialerLinks.mac.version})`,
    value: {
      icon: "linux",
      link: dialerLinks.linux.link,
    },
  });
});
</script>

<style scoped>
.menu-item {
  color: inherit;
  font-size: 14px;
  padding: 10px 15px;
}
</style>
