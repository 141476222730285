<template>
  <HWMenu
    arrow
    placement="bottom-end"
    content-styles="width: 225px; padding: 20px; padding-bottom:12px;"
  >
    <template #trigger>
      <div
        style="cursor: pointer"
        class="avatar avatar-sm"
        v-html="user?.avatarTag"
      ></div>
    </template>

    <template #content>
      <div class="tx-13">
        <div class="dropdown-item no-bg">
          <h6
            style="font-size: 14px"
            class="tx-semibold mg-b-5 d-flex align-items-center"
          >
            <strong> {{ user?.firstname + " " + user?.lastname }} </strong>

            <!-- <span class="badge badge-warning ml-1" style="font-weight: 500">
              Pro {{}}
            </span> -->
          </h6>
          <p class="mg-b-20 tx-12 tx-color-03">{{ user?.role }}</p>
        </div>
        <div style="cursor: pointer" class="dropdown-item no-bg">
          <div class="custom-control custom-switch">
            <input
              id="customSwitch"
              :checked="isUserAvailable"
              type="checkbox"
              class="custom-control-input"
              @input="isUserAvailable = ($event as any).target.checked"
              @change="handleUserAvailabilityStatus"
            />
            <label
              style="cursor: pointer"
              class="custom-control-label"
              for="customSwitch"
            >
              Available
            </label>
          </div>
        </div>
        <a
          :href="getAbsoluteUrl('/settings/profile')"
          class="dropdown-item tx-13"
        >
          <mdicon size="18" name="cog-outline" class="nudge-icon-top" />
          Account Settings
        </a>
        <a
          :href="getAbsoluteUrl('/notification-center')"
          class="dropdown-item tx-13"
        >
          <mdicon size="18" name="bell-outline" class="nudge-icon-top"></mdicon>
          Notification Settings
        </a>
        <a
          v-if="!justcallEnv"
          :href="
            featureFlags.billing
              ? getAbsoluteUrl('/billing')
              : getAbsoluteUrl('/settings/billing')
          "
          class="dropdown-item tx-13"
        >
          <mdicon size="18" name="credit-card-outline" class="nudge-icon-top" />
          Billing
        </a>
        <div class="dropdown-divider mt-1" style="margin-bottom: 12px"></div>

        <a
          class="dropdown-item tx-13"
          style="padding: 10px 15px"
          @click.prevent="handleLogout"
        >
          <mdicon size="17" name="logout" class="nudge-icon-top" />
          Sign Out
        </a>
      </div>
    </template>
  </HWMenu>
</template>

<script lang="ts" setup>
import * as Sentry from "@sentry/browser";

import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

import UserAvailable from "@/stores/enums/userAvailable.enum";
import { getAbsoluteUrl } from "@/utils/helperFunction";

const userStore = useUserStore();

const { user, userOnlineStatus, featureFlags } = storeToRefs(userStore);

const isUserAvailable = ref(false);

const justcallEnv = import.meta.env.VITE_JUSTCALL_ENV;

onMounted(() => {
  if (userOnlineStatus.value === UserAvailable.available) {
    isUserAvailable.value = true;
  } else {
    isUserAvailable.value = false;
  }
});

const handleUserAvailabilityStatus = () => {
  try {
    userStore.updateUserAvailability(
      isUserAvailable.value
        ? UserAvailable.available
        : UserAvailable.unavailable
    );
  } catch (err) {
    isUserAvailable.value = !isUserAvailable.value;
    throw err;
  }
};

const handleLogout = () => {
  if (
    import.meta.env.MODE == "production" ||
    import.meta.env.MODE == "staging"
  ) {
    Sentry.setUser(null);
  }
  localStorage.clear();
  window.location.href = import.meta.env.VITE_APP_BASE_URL + "/logout";
};
</script>

<style scoped>
.avatar {
  padding: 3px;
  box-sizing: content-box;
}

.avatar:hover {
  background: rgba(0, 0, 0, 0.08);
  border-radius: 100px;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item.no-bg {
  background: transparent !important;
  cursor: auto;
}

.nudge-icon-top {
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #54b85a;
  background-color: #54b85a;
}
</style>
