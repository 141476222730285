export const chatLocaleObject = {
  name: "chat",
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "few seconds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1M",
    MM: "%dM",
    y: "1Y",
    yy: "%dY",
  },
};
