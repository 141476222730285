<template>
  <div ref="drawerRef" class="drawer" :style="{ right }">
    <slot :close-drawer="closeDrawer"></slot>
  </div>
</template>

<script lang="ts" setup>
import { useRootStore } from "@/stores/root";
import { onClickOutside } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";

type Props = {
  width?: string;
  bgColor?: string;
};

const props = withDefaults(defineProps<Props>(), {
  width: "300px",
  bgColor: "#fff",
});

const rootStore = useRootStore();
const { drawer } = storeToRefs(rootStore);

const drawerRef = ref(null);
const hasDrawerMounted = ref(false);

const right = computed(() => {
  return hasDrawerMounted.value ? "0" : `calc(-${props.width})`;
});

onClickOutside(drawerRef, () => {
  closeDrawer();
});

onMounted(() => {
  // For animation
  setTimeout(() => {
    hasDrawerMounted.value = true;
  }, 100);
});

const closeDrawer = () => {
  hasDrawerMounted.value = false;

  // For animation
  setTimeout(() => {
    drawer.value = null;
  }, 100);
};
</script>

<style scoped>
.drawer {
  position: fixed;
  top: 0;
  min-width: v-bind(width);
  max-width: v-bind(width);
  height: 100%;
  background: v-bind(bgColor);
  box-shadow: 0 0 18px rgba(28, 39, 60, 0.06);
  z-index: 10000;
  padding: 12px;
  transition: all 0.3s;
  border-left: 1px solid #ccc;
}
</style>
