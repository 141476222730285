import { useInboxStore } from "@/stores/inbox";
import { useMsgStore } from "@/stores/msg";
import { nanoid } from "nanoid";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

const useCompose = () => {
  const route = useRoute();
  const msgStore = useMsgStore();
  const inboxStore = useInboxStore();

  const { msgComposers } = storeToRefs(msgStore);
  const { inboxesMap, inboxMailComposers, inboxAccessMap } =
    storeToRefs(inboxStore);

  const inboxId = computed(() => route.params.id as string);

  const openCompose = () => {
    // for universal with id or single inbox
    if (inboxId.value !== "me") {
      const foundInbox = inboxesMap.value[inboxId.value];
      if (!inboxAccessMap.value.discussion) {
        if (foundInbox?.type === "sms" || foundInbox?.type === "whatsapp") {
          msgComposers?.value?.push({
            id: nanoid(),
            composerFor: foundInbox.type,
          });
        } else if (
          foundInbox?.type === "mail" ||
          foundInbox?.type === "custom"
        ) {
          inboxMailComposers.value.push({
            id: nanoid(),
          });
        }
      } else {
        msgComposers?.value?.push({
          id: nanoid(),
          composerFor: "discussion",
        });
      }
    } else {
      if (!inboxAccessMap.value.discussion) {
        inboxMailComposers.value.push({
          id: nanoid(),
        });
      } else {
        msgComposers?.value?.push({
          id: nanoid(),
          composerFor: "discussion",
        });
      }
    }
  };

  return {
    openCompose,
  };
};

export default useCompose;
