import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { storeToRefs } from "pinia";
import { AssignmentOnReply } from "@/stores/enums/assignmentOnReply.enum";
import { useInboxStore } from "@/stores/inbox";
import { useThreadStore } from "@/stores/thread";
import { useUserStore } from "@/stores/user";

const useAutoAssigner = () => {
  const autoAssign = (
    inboxId: string,
    threadId: string,
    forceAssign?: boolean
  ) => {
    const userStore = useUserStore();
    const inboxStore = useInboxStore();
    const threadStore = useThreadStore();

    const { user } = storeToRefs(userStore);
    const { threadsDetailMap } = storeToRefs(threadStore);
    const { inboxesMap } = storeToRefs(inboxStore);

    if (!user.value) return;

    // Check if current thread is unassigned
    const isAssigned = threadsDetailMap.value[threadId]?.threadDetail.assignedTo
      ? true
      : false;

    // if unassigned, check if assignmentOnReply on current inbox is for reply.
    if (!isAssigned || forceAssign) {
      const { assignmentOnReply } = inboxesMap.value[inboxId];

      if (
        assignmentOnReply === AssignmentOnReply.assignOnBoth ||
        assignmentOnReply === AssignmentOnReply.assignOnReply
      ) {
        const userName = user.value.firstname + " " + user.value.lastname;
        // assign the current user and push the logs
        if (threadsDetailMap.value[threadId]) {
          let avatarUrl: string | null = user.value.avatarUrl;
          if (user.value.avatarUrl === "") {
            avatarUrl = null;
          }
          threadsDetailMap.value[threadId]!.threadDetail.assignedTo = {
            avatarTag: user.value.avatarTag,
            avatarUrl: avatarUrl,
            email: user.value.email,
            firstname: user.value.firstname,
            id: user.value.id,
            lastname: user.value.lastname,
            displayName: user.value.firstname + " " + user.value.lastname,
          };
          const now = dayjs().toISOString();
          const content = `${userName} assigned the conversation to themselves`;
          threadsDetailMap.value[threadId]?.threadItems?.push({
            type: "log",
            content,
            createdAt: now,
            id: content + now + "",
          });
        }
      }
    }
  };

  return {
    /**
     * Assign current user to unassigned thread based on `assignmentOnReply` property.
     *
     * `forceAssign=true` bypasses unassign check.
     */
    autoAssign,
  };
};

export default useAutoAssigner;
