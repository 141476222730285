export enum ThreadPriorityEnum {
  high = "high",
  medium = "medium",
  low = "low",
  choose = "choose",
}

export enum ThreadPriorityEnumNumeric {
  high = "1",
  medium = "2",
  low = "4",
  choose = "3",
}
