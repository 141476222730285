<template>
  <div class="d-flex align-items-center">
    Email Sent
    <div
      v-if="actionLoading"
      class="spinner-border spinner-border-sm tx-primary mg-l-4"
      style="width: 0.9rem; height: 0.9rem"
    ></div>

    <span
      v-else
      class="tx-primary mg-l-4 undo-txt"
      style="cursor: pointer"
      @click.stop="undoSendEmail"
    >
      Undo.
    </span>
  </div>
</template>

<script lang="ts" setup>
import useMitt from "@/functions/useMitt";
import { useEmailStore } from "@/stores/email";
import { useThreadStore } from "@/stores/thread";
import type { EmailDraft } from "@/stores/types/email";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const props = defineProps<{
  inboxId: string;
  draftData: EmailDraft;
  isComposer: boolean;
}>();

const emit = defineEmits<{
  (e: "close-toast"): void;
}>();

const { emitter } = useMitt();
const emailStore = useEmailStore();
const threadStore = useThreadStore();

const { threadsDetailMap } = storeToRefs(threadStore);

const actionLoading = ref(false);

const undoSendEmail = async () => {
  actionLoading.value = true;

  const threadId = String(props.draftData.threadID);

  await emailStore.undoSend(props.draftData.draftID, props.inboxId, threadId);

  // Clearing the threadmap and closing the broad thread view so that it loads again.
  delete threadsDetailMap.value[threadId];

  emitter.emit("undo-toast:undo", {
    draftData: props.draftData,
    isComposer: props.isComposer,
  });
  emit("close-toast");

  actionLoading.value = false;
};
</script>

<style scoped>
.undo-txt:hover {
  text-decoration: underline;
}
</style>
