<template>
  <Tippy
    ref="tippyRef"
    :placement="placement ? placement : 'bottom'"
    trigger="click"
    :interactive="true"
    :z-index="100000"
    :max-width="maxWidth"
    theme="light"
    content-class="menu-content"
    :offset="offset"
    :arrow="arrow"
    @show="menuShown = true"
    @hidden="menuShown = false"
  >
    <!-- :on-hidden="toggleMenuShow(false)" -->
    <template #default>
      <slot name="trigger">Menu</slot>
    </template>

    <template #content>
      <div
        :style="{
          background: bgColor ?? '#fff',
          minWidth: minWidth ?? '100px',
          borderRadius: '.25rem',
        }"
      >
        <slot name="loader">
          <ul v-if="loading" class="list-group">
            <li class="list-group-item" style="border: none">
              <Skeletor
                pile
                height="8px"
                width="100%"
                style="border-radius: 0.25rem"
              />
            </li>
          </ul>
        </slot>

        <ul
          v-if="!loading && menuItems"
          class="list-group"
          :style="listContainerStyles"
        >
          <template v-if="menuItems.length">
            <li
              v-for="(item, i) in menuItems"
              :key="i"
              class="list-group-item list-group-item-action"
              :style="listStyles"
              style="border: none"
              :class="{ 'pd-y-4': denseList }"
              @click="handleListItemClick($event, item)"
            >
              <slot name="menu-item" :item="item" :close-menu="closeMenu">
                {{ item.text }}
              </slot>
            </li>
          </template>
          <li
            v-else
            class="list-group-item"
            :style="listStyles"
            style="border: none; cursor: default"
            :class="{ 'pd-y-4': denseList }"
          >
            {{ noItemText ?? "No item found." }}
          </li>
        </ul>

        <div
          v-else-if="!loading && !menuItems"
          style="padding: 10px 15px"
          :style="contentStyles"
        >
          <slot name="content" :close-menu="closeMenu" :menu-shown="menuShown">
            Menu content
          </slot>
        </div>
      </div>
    </template>
  </Tippy>
</template>

<script lang="ts" setup>
import { Tippy } from "vue-tippy";
import type { Placement } from "tippy.js";
import { nextTick, ref, type StyleValue } from "vue";

type MenuItem = {
  text: string;
  value: any;
};

const props = defineProps<{
  maxWidth?: string;
  minWidth?: string;
  placement?: Placement;
  bgColor?: string;
  stopClickPropagation?: boolean;
  /**
   * If passed, will create a list on the menu. Then @click:item could be used.
   */
  menuItems?: MenuItem[];
  /**
   * Reduce list top and bottom padding to 4px.
   */
  denseList?: boolean;
  /**
   * Styles for each li item.
   */
  listStyles?: StyleValue;
  listContainerStyles?: StyleValue;
  contentStyles?: StyleValue;
  loading?: boolean;
  /**
   * Works only when menuItems are provided.
   */
  noItemText?: string;
  /**
   * [x, y]
   */
  offset?: [number, number];
  arrow?: boolean;
}>();

const emit = defineEmits<{
  (e: "click:item", item: MenuItem): void;
}>();

const menuShown = ref(false);

const tippyRef = ref<Record<string, any> | null>();

const handleListItemClick = (
  event: MouseEvent,
  item: { text: string; value: any }
) => {
  if (props.stopClickPropagation) {
    event.stopPropagation();
  }
  emit("click:item", item);
  closeMenu();
};

const closeMenu = () => {
  if (tippyRef.value) {
    tippyRef.value.hide();
  }
};
</script>

<style>
.tippy-box[data-theme~="light"] {
  border: 1px solid rgba(154, 161, 177, 0.3);
  box-shadow: 0 0 20px 4px rgb(154 161 177 / 8%),
    0 4px 80px -8px rgb(36 40 47 / 10%), 0 5px 4px -2px rgb(91 94 105 / 15%);
}

.tippy-box[data-theme~="light"] .tippy-content {
  padding: 0;
}

.menu-content {
  border-radius: 0.25rem;
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
