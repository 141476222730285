/*
  Note: When in need of emitter/eventBus, create an event name containing its location of emitting
  separated by ":" and then the name of the event
*/

import type { ThreadAttachment } from "@/stores/types/thread";
import type { Article, SavedReply } from "@/stores/types/inbox";
import mitt from "mitt";
import type FroalaEditor from "froala-editor";
import type { EmailDraft } from "@/stores/types/email";

export declare type ShortcutType =
  | "deleteThread"
  | "closeThread"
  | "uncollapseEmails";

export type EmitterEvents = {
  "inbox-view-main-header:click-back": {
    reloadThread: boolean;
  };
  // Text editor emits
  "text-editor-saved-reply:insert": {
    snippet: SavedReply["snippet"];
    editorId: string;
    attachments: ThreadAttachment[];
    subject?: string;
    editor?: FroalaEditor;
  };
  "text-editor-saved-reply-subject-froala:insert": {
    subject: string;
  };
  "text-editor-article-modal:click": {
    article: Article;
    editorId: string;
    editor?: FroalaEditor;
  };
  "text-editor-whatsapp-template-modal:click": {
    template: string;
    templateParameters: {
      type: "text";
      text: string;
    }[];
    headerParameters: {
      type: "text";
      text: string;
    }[];
    imageParameter: string;
    locationParameter: {
      placeholder: string;
      val: string;
    }[];
    templateCategory: string;
    templateLang: string;
    templateName: string;
    templateNameSpace: string;
    editorId: string;
    templateId: number;
  };
  "text-editor-link-modal:click": {
    link: string;
    linkPos?: {
      from: number;
      to: number;
    };
    editorId: string;
  };
  "text-editor-focus": void;
  "ai-tab-rephrase:click": {
    tone: string;
    editor: FroalaEditor;
    language: string;
    action?: "translate";
  };
  "ai-tab-regenerate:click": {
    editor: FroalaEditor;
    suggestions: string;
    threadId: string;
    inboxId: string;
    isComposer: boolean;
    draftId: number;
  };
  "inbox-actions-snooze-modal:snooze-email": {
    date: string;
    editorId: string;
  };
  "inbox-actions-snooze-modal:snooze-action-custom": void;
  "inbox-view-list:view-all-conv": null;
  "inbox-view-list-header:reload-threads": {
    loadThreadsPage: boolean;
  };
  "inbox-view-sidebar:add-views": {
    name: string;
    base: string;
    viewInboxId: string;
  };
  "universal-view-sidebar:add-views": {
    name: string;
    base: string;
    viewInboxId: string;
  };
  "autopilot-suggestion-fetched": void;
  "contact-panel-activity-modal:create-activity": {
    activities: any;
  };
  "contact-panel-new-group-modal:create-new-group": {
    groups: any;
  };
  "contact-panel-group-modal:assign-group": {
    groups: any;
  };
  "inbox-view-main-content-email:email-respond": void;
  "inbox-view-main-content-custom:custom-respond": void;
  "inbox-mail-composer:mount": void;
  "text-editor:mount": void;
  "inbox-actions-move-modal:move": void;
  "inbox-view-main-content-replier-email:sendAndClose": void;
  "trimmed-node:change": void;
  "contact-custom-fields-update:inbox": void;
  "use-shortcut:trigger": {
    shortcutFor: ShortcutType;
  };
  "undo-toast:undo": {
    draftData?: EmailDraft;
    isComposer: boolean;
  };
  /**
   * For collision prevention of dropdown for now.
   *
   */
  "dropdown:open": {
    dropdownOf: "viewing-user" | "assign-user" | "priority" | "snooze" | "tag";
  };
};

const emitter = mitt<EmitterEvents>();

const useMitt = () => {
  return {
    emitter,
  };
};

export default useMitt;
