import { createRouter, createWebHistory } from "vue-router";
import useMitt from "@/functions/useMitt";
import { defineAsyncComponent } from "vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: "single",
      path: "/inboxes/:id/:label/:threadId?",
      component: defineAsyncComponent(() => import("@/views/InboxPage.vue")),
    },
    {
      name: "universal",
      path: "/universal/:id/:label/:threadId?",
      component: defineAsyncComponent(
        () => import("@/views/UniversalPage.vue")
      ),
    },
    {
      name: "broadcast",
      path: "/whatsapp/:id/broadcasts",
      component: defineAsyncComponent(
        () => import("@/views/BroadcastPage.vue")
      ),
      children: [
        {
          path: "create",
          component: defineAsyncComponent(
            () => import("@/views/BroadcastPage.vue")
          ),
        },
        {
          path: ":broadcastId?",
          component: defineAsyncComponent(
            () => import("@/views/BroadcastPage.vue")
          ),
        },
      ],
    },
    {
      name: "integrationIframe",
      path: "/iframes/integrations",
      component: defineAsyncComponent(
        () => import("@/views/IntegrationIframe.vue")
      ),
      meta: {
        hideHeader: true,
        noLoader: true,
        noScripts: true,
      },
    },
    {
      name: "teams",
      path: "/settings/teams",
      component: defineAsyncComponent(() => import("@/views/SettingPage.vue")),
    },
  ],
});

// @Bug: on page change (univ -> single or single -> univ) previous page emitter remains active
router.beforeEach((to, from, next) => {
  const { emitter } = useMitt();

  if (
    (from.name === "universal" && to.name === "single") ||
    (from.name === "single" && to.name === "universal")
  ) {
    emitter.all.clear();
  }

  next();
});

export default router;
