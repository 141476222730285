import { initializeApp, type FirebaseOptions } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { useUserStore } from "@/stores/user";
import { useToast } from "vue-toastification";
import SocketListener from "./listeners";
import { storeToRefs } from "pinia";

let config: FirebaseOptions = {
  apiKey: "AIzaSyCCXmvojmIn-QkRKBP-ySP_CaUxNJTXDC0",
  authDomain: "helpwise-websocket-4752f.firebaseapp.com",
  databaseURL: "https://helpwise-websocket-4752f.firebaseio.com",
  projectId: "helpwise-websocket-4752f",
  storageBucket: "helpwise-websocket-4752f.appspot.com",
  messagingSenderId: "270528512292",
  appId: "1:270528512292:web:1351ce00cf1ad744ba73b1",
  measurementId: "G-9MDPX07YHD",
};

if (import.meta.env.MODE === "staging") {
  config = {
    apiKey: "AIzaSyDr528_Z4WAk8S7lHJMZz5XsJLLOCatAoo",
    authDomain: "helpwise-realtime-staging.firebaseapp.com",
    databaseURL:
      "https://helpwise-realtime-staging-default-rtdb.firebaseio.com",
    projectId: "helpwise-realtime-staging",
    storageBucket: "helpwise-realtime-staging.appspot.com",
    messagingSenderId: "778412669261",
    appId: "1:778412669261:web:f05b79ed85707ba57a1f04",
    measurementId: "G-90XYKCCPCY",
  };
}

if (import.meta.env.VITE_JUSTCALL_ENV == "1") {
  config = {
    apiKey: "AIzaSyAUJcW04jH3sJ1wfdbEnIR7_EMTesH3pKE",
    authDomain: "emailservices-415213.firebaseapp.com",
    databaseURL: "https://emailservices-415213-default-rtdb.firebaseio.com",
    projectId: "emailservices-415213",
    storageBucket: "emailservices-415213.appspot.com",
    messagingSenderId: "986453452403",
    appId: "1:986453452403:web:814849c7af94a319a76c06",
  };
}

const app = initializeApp(config);
const toast = useToast();
const auth = getAuth(app);
const db = getDatabase(app);
let parentRef = "/Account-";

let listener: null | InstanceType<typeof SocketListener> = null;
/**
 * Authenticate the user for realtime database.
 *
 * Also setup firebase listeners.
 */
async function initFirebase() {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  if (!user.value) return;

  try {
    const { token } = await userStore.fetchSocketToken();

    const accountId = user.value.accountID;
    parentRef += accountId;

    await signInWithCustomToken(auth, token);

    listener = new SocketListener(db, parentRef);

    // Initializing listeners
    listener.addNoteListener();
    listener.addTagListener();
    listener.incomingThreadUpdatesListener();
    listener.outgoingThreadUpdatesListener();
    listener.snoozeThreadListener();
    listener.moveToInboxThreadListener();
    listener.closeThreadListener();
    listener.deleteMessageListener();
    listener.deleteNoteListener();
    listener.assignThreadListener();
    listener.spamThreadListener();
    listener.deleteThreadListener();
    listener.notificationListener(user.value.id);
    listener.playNotificationSoundListener(user.value.id);
    listener.threadSeenListener();
    if (
      import.meta.env.MODE == "production" &&
      import.meta.env.VITE_JUSTCALL_ENV != "1"
    ) {
      listener.newReleaseListener();
    }
  } catch (err) {
    toast.error("Something went wrong, please try reloading.");
    console.error(err);
  }
}

export { initFirebase, listener, db, parentRef };
