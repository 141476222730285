import $api from "@/api";
import { defineStore } from "pinia";
import type { DialerLinks } from "./types/dialer";

export const useDialerStore = defineStore({
  id: "dialer",
  state: () => ({}),
  actions: {
    async getDialerLinks(): Promise<DialerLinks> {
      const res = await $api.get(`/twilioVoice/dialer/links`);
      const { status, message, data } = res.data;
      if (status === "error") {
        throw new Error(message);
      }
      return data.dialerLinks;
    },
  },
});
